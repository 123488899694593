import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import {ORSMasterListing} from "components/globals/ORSMasterListing"

export default function UserCars() {

	const sections = [
    { 
      params: {
				elementId: "cars", 
				headingTitle: "Recent Cars",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: "garage",
				omit: "none",
				limit: "24",
				type: "car", 
				pagination: true,
				createButton: true,
        createButtonPath: "/new/car"
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
				categoryBadge: true,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    }
  ]
	
	return (
		<>
			<ORSSubnav>
				<SubnavItem to="/cars" label="Cars Home" />
				<SubnavItem to="/cars/brands" label="Brands" />
				<SubnavItem active to="/cars/users" label="User Cars" />
				<SubnavItem to="/cars/spotted" label="Spotted" />
			</ORSSubnav>

			<ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars" label="Cars" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="User Cars" />
			</ORSBreadcrumbs>

			
			<PSPage>
				{sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}
			</PSPage>
		</>
	);
};
