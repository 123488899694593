import { React } from "react";
import { getTypeString, getCategoryString } from "helpers/utils"

export function TypeBadge({
	entryType,
	type,
}) {

	return (
		<>
			{ type ? (
				<div 
					className={`
						inline-block
						py-[2px]
						px-[5px] 
						font-bold
						text-[11px]
						rounded-md
						tracking-[.06em]
						bg-black text-white`}
					>
						{ getTypeString(entryType, type) }
				</div>
			) : null }
			
		</>
  );
}

export function CategoryBadge({
	entryType,
	type,
	category,
}) {

	return (
		<>
			{ type ? (
				<div 
					className={`
						inline-block
						py-[2px]
						px-[5px] 
						font-bold
						text-[11px]
						rounded-md
						tracking-[.06em]
						bg-[#444444] text-white`}
					>
						{ getCategoryString(category) }
				</div>
			) : null }
			
		</>
  );
}