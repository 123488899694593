import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import {ORSMasterListing} from "components/globals/ORSMasterListing"

import marquee from "assets/images/marquees/cars.jpg";
import marqueeMobile from "assets/images/marquees/cars-mobile.jpg";


export default function Cars() {

  const sections = [
    { 
      params: {
				elementId: "cars", 
				headingTitle: "Recent User Cars",
				headingButtonUrl: "/cars/users",
				headingButtonText: "View All",
				apiPath: "garage",
				omit: "none",
				limit: "6",
				type: "car", 
				pagination: false,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: false,
        categoryBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
    { 
      params: {
				elementId: "recentSpotted", 
				headingTitle: "Recent Spots",
				headingButtonUrl: "/cars/spotted",
				headingButtonText: "View All",
				apiPath: "post/type/spot",
				omit: "none",
				limit: "6",
				type: "post", 
				pagination: false,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: false,
        categoryBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]


  return (
    <>
      <ORSSubnav>
        <SubnavItem active to="/cars" label="Cars Home" />
        <SubnavItem to="/cars/brands" label="Brands" />
        <SubnavItem to="/cars/users" label="User Cars" />
        <SubnavItem to="/cars/spotted" label="Spotted" />
      </ORSSubnav>

      <ORSBreadcrumbs>
        <BreadcrumbLink to="/" label="Home" />
        <BreadcrumbSpacer />
        <BreadcrumbSpan label="Cars" />
      </ORSBreadcrumbs>

      <div className="relative">
        <img src={marquee} alt="Open Road Society" className="hidden md:block w-full" />
        <img src={marqueeMobile} alt="Open Road Society" className="block md:hidden w-full" />
      </div>
      
      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
