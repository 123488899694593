import React from "react";

export default function PSCardImageContainer({children}) {
  return (
    <>
      <div className="relative rounded-md overflow-hidden aspect-[4/2.75]">
				{children}
      </div>
    </>
  );
};
