import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";

import marquee from "assets/images/marquees/users.jpg";
import marqueeMobile from "assets/images/marquees/users-mobile.jpg";

export default function Society() {

  const sections = [
    { 
      params: {
				elementId: "recentUsers", 
				headingTitle: "Recent Members",
				headingButtonUrl: "/society/members",
				headingButtonText: "View All",
				apiPath: "users",
				omit: "none",
				limit: "6",
				type: "user", 
				pagination: false,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: true,
        categoryBadge: false,
        userBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
    { 
      params: {
				elementId: "recentEvents", 
				headingTitle: "Recent Events",
				headingButtonUrl: "/society/events",
				headingButtonText: "View All",
				apiPath: "events",
				omit: "none",
				limit: "6",
				type: "event", 
				pagination: false,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: true,
        categoryBadge: false,
        userBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]

  return (
    <>

      <ORSSubnav>
        <SubnavItem active to="/society" label="Society" />
        <SubnavItem to="/society/members" label="Members" />
        {/* <SubnavItem to="/society/groups" label="Groups" /> */}
        <SubnavItem to="/society/events" label="Events" />
        <SubnavItem to="/society/rallys" label="Drives & Rallys" />
      </ORSSubnav>

      <ORSBreadcrumbs>
        <BreadcrumbLink to="/" label="Home" />
        <BreadcrumbSpacer />
        <BreadcrumbSpan label="Society" />
      </ORSBreadcrumbs>

      <div className="relative">
        <img src={marquee} alt="Open Road Society" className="hidden md:block w-full" />
        <img src={marqueeMobile} alt="Open Road Society" className="block md:hidden w-full" />
      </div>

      
      <PSPage>
        {/* <h1 className="text-3xl font-bold mt-md">The Society</h1> */}
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
