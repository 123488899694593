import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";

export default function Listings() {

  const sections = [
    { 
      params: {
				elementId: "allListings", 
				headingTitle: "Listings",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: "post/type/listing",
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
        createButton: true,
        createButtonPath: "/new/post"
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        userBadge: true,
        typeBadge: false,
        categoryBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]

  return (
    <>
      <ORSSubnav>
        <SubnavItem to="/marketplace" label="Marketplace" />
        <SubnavItem active to="/marketplace/listings" label="Listings" />
        <SubnavItem to="/marketplace/wants" label="Want-Ads" />
      </ORSSubnav>

      <ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/marketplace" label="Marketplace" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="Listings" />
      </ORSBreadcrumbs>

      
      <PSPage>
        <h1 className="text-3xl font-bold mt-md">Marketplace Listings</h1>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
