import React, { useState } from "react";
import { FaEllipsisH, FaRegEdit } from "react-icons/fa";
import { useAuth } from "providers/AuthContext";
import { getEntryTypePath } from "types/baseTypes";
import DeleteEntryUI from "components/globals/utils/DeleteEntryUI";
import TapButton from "components/globals/ui/input/TapButton";
import Pane from "components/modals/Pane";

export default function EntryUserActions({ comparison, item }) {
  const { userInfo, isFetching } = useAuth();
  const [visible, setVisible] = useState(false);

  const showPane = function () {
    setVisible(true);
  };

  const hidePane = function () {
    setVisible(false);
  };

  return (
    <>
      {!isFetching && comparison === userInfo?.user_id && (
        <div className="relative">
          <span
            className="
            relative 
            bg-secondary
            text-brg
            mt-2 
            overflow-hidden 
            rounded-small
            w-[30px]
            h-[30px]
            flex
            items-center
            justify-center
            cursor-pointer"
            onClick={showPane}
          >
            <FaEllipsisH className="relative" />
          </span>

          <Pane shorter visible={visible} onClose={hidePane}>

            <TapButton
              inGrid
              link={`/edit/${getEntryTypePath(item?.entry_type)}/${item.internal_id}`}
              bgColor="brg"
              textColor="white"
              Icon={FaRegEdit}
              title="Edit" />

            <div className="my-2"></div>

            <DeleteEntryUI largeBtn entry={item} />
          </Pane>
        </div>
      )}
    </>
  );
}
