import React from 'react';
import { useAuth } from 'providers/AuthContext';
import { DashboardTemplate } from './Dashboard';
import {ORSMasterListing} from "components/globals/ORSMasterListing"

export default function Dashboard() {

	const { userInfo, isFetching } = useAuth();

	const sections = [
    {
			params: {
				elementId: "cars", 
				headingTitle: "Your Cars",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/garage",
				omit: "none",
				limit: "6",
				type: "car", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/car"
			},
      displayOptions: {
        layout: "card",
        yoursBadge: false,
        typeBadge: false,
				categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      },
    },
	];

	return (
		<>
			{!isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="My Garage">

					{sections.map(({params, displayOptions}) => (
						<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
					))}
					
				</DashboardTemplate>
			) : null }
		</>
	);
}