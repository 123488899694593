import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useState, useEffect, useCallback } from "react";
import { useDataCache } from "cache/DataCacheContext";
import { useGetUserEntryQuery } from "services/auth/authService";
import DOMPurify from "dompurify";

export function formatDate(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function formatTitle(title) {
  if (title?.props) {
    return title;
  } else {
    return title.replace("&amp;", "&");
  }
}

export function sanitizeInput(input) {
  return DOMPurify.sanitize(input);
}

export function scrollToComments() {
  const commentsSection = document.getElementById('comments');
  if (commentsSection) {
    commentsSection.scrollIntoView({ behavior: 'smooth' });
  }
};

export function prepareFormData(data, clearImage) {
  console.log("clear image", clearImage);
  let fd = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    // Log the type of value
    // if (Array.isArray(value)) {
    // 	console.log(`${key} is an array`, value);
    // } else if (typeof value === 'object' && value !== null) {
    // 	console.log(`${key} is an object`, value);
    // } else {
    // 	console.log(`${key} is of type ${typeof value}`, value);
    // }

    if (key === "file") {
      console.log("file val", value);
    }

    if (key === "file" && !clearImage) {
      console.log(
        "key === 'file' && !clearImage",
        key === "file" && !clearImage
      );
      fd.append(key, value[0]);
    } else {
      if (key !== "file") {
        fd.append(key, sanitizeInput(value));
      }
    }
  });

  return fd;
}
export function scrollToTop() {
  window.scrollTo(0, 0);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}


export const useFetchData = (url, dependencies = [], options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchDataWithCache } = useDataCache();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchDataWithCache(url);
      setData(response);
      setError(null);
    } catch (err) {
      console.error(`Error fetching data from ${url}:`, err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url, ...dependencies]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error };
};


export const useFetchLikeData = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchDataWithCache } = useDataCache();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchDataWithCache(url);
      setData(response);
      setError(null);
    } catch (err) {
      console.error(`Error fetching data from ${url}:`, err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error };
};


export const useFetchAuthedData = (url, dependencies = [], options = {}) => {
  const {
    data: queryData,
    error: queryError,
    isLoading: queryLoading,
  } = useGetUserEntryQuery(url);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(queryLoading);

    if (!queryLoading) {
      if (queryError) {
        setError(queryError);
        setData(null);
      } else {
        setError(null);
        setData(queryData);
      }
    }

    // if (!queryLoading && options.scrollToTop) {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }
  }, [queryData, queryError, queryLoading, options.scrollToTop]);

  return { data, loading, error };
};

export const fetchPostAssociation = async (userToken, type) => {
  if (!userToken) {
    throw new Error("No token found");
  }

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URI}/api/protected/all/${type}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch post association");
  }

  const data = await response.json();
  return data.entries;
};

export const fetchAndMapAssociations = async (userToken, associationTypes) => {
  try {
    const fetchPromises = associationTypes.map((type) =>
      fetchPostAssociation(userToken, type).then((data) => ({ type, data }))
    );
    return await Promise.all(fetchPromises);
  } catch (err) {
    console.error("Error fetching associations:", err);
    throw err;
  }
};

export const fetchCarDB = async () => {
  
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URI}/api/cars/all`,
  );

  if (!response.ok) {
    throw new Error("Failed to fetch carDB");
  }

  const data = await response.json();
  return data;

};

export function clearPostData(updater, schema) {
  updater(() => {
    return Object.keys(schema).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
  });
}

export function loadExistingData(updater, schema, data) {
  updater(() => ({
    ...Object.keys(schema).reduce((acc, key) => {
      acc[key] = data?.entry?.[key] ?? "";
      return acc;
    }, {}),
  }));
}

export function setCategory(updater, categories, type) {
  const defaultCategory = categories
    .filter((category) => category.type === type)
    .flatMap((category) => category.items)[0]?.key;
  updater("category", defaultCategory || "");
}

export const createFormData = (postData, schema) => {
  const fd = new FormData();

  Object.keys(schema).forEach((key) => {
    if (postData[key] !== undefined) {
      if (key === "gallery" && Array.isArray(postData[key])) {
        postData[key].forEach((imgObj, index) => {
          // console.log("imgObj", imgObj);
          // new images, append the file
          if (imgObj.new) {
            fd.append(key, imgObj.file);
            fd.append(`modifyImage:new:${imgObj.file.name}`, index);
          } else {
            if (imgObj.remove) {
              fd.append(`modifyImage:remove:${index}`, imgObj.filename);
            } else {
              fd.append(
                `modifyImage:setIndex:${index}:${imgObj.filename}`,
                imgObj.index
              );
            }
          }
        });
      } else if (key === "banners" && Array.isArray(postData[key])) {
        postData[key].forEach((imgObj, index) => {
          // console.log("imgObj", imgObj);
          // new images, append the file
          if (imgObj.new) {
            fd.append(key, imgObj.file);
            fd.append(`modifyImageBanner:new:${imgObj.file.name}`, index);
          } else {
            if (imgObj.remove) {
              fd.append(`modifyImageBanner:remove:${index}`, imgObj.filename);
            } else {
              fd.append(
                `modifyImageBanner:setIndex:${index}:${imgObj.filename}`,
                imgObj.index
              );
            }
          }
        });
      } else {
        fd.append(key, sanitizeInput(postData[key]));
      }
    }
  });

  // for (const pair of fd.entries()) {
  //   console.log(pair[0], pair[1]);
  // }

  return fd;
};


export const getTypeString = (entryType, type) => {
  if(entryType === 'post') {
    switch (type?.toLowerCase()) {
      case "listing":
        return "Listing";
      case "want":
        return "Want-Ad";
      case "record":
        return "Car Record";
      case "spot":
        return "Spot";
      case "update":
        return "Update";
      default:
        return "Post";
    }
  } else {
    switch (entryType?.toLowerCase()) {
      case "garagecar":
        return "User Car";
      case "event":
        return "Event";
      case "group":
        return "Group";
      default:
        return "TEST";
    }
  }
}

export const getCategoryString = (category) => {
  console.log(category.toLowerCase())
  switch (category?.toLowerCase()) {
    case "wild":
      return "In the Wild";
    case "mod":
      return "Mod";
    case "general":
      return "General";
    case "new":
      return "New";
    case "other":
      return "Other";
    case "c&c":
      return "Cars & Coffee";
    case "want1":
      return "General";
    case "listing1":
      return "General";
    case "restoration":
      return "Restoration";
    case "drive":
      return "Drive";
    case "maintenance":
      return "Maintenance";
    case "repair":
      return "Repair";
    case "used":
      return "Used";
    case "car":
      return "Car";
    case "accerssories":
      return "Acceccory";
    case "show":
      return "Car Show";
    case "museum":
      return "Museum";
    case "note":
      return "Note/Doc";
    case "detailing":
      return "Detailing";
    case "daily":
      return "Daily";
    case "weekend":
      return "Weekender";
    case "project":
      return "Project Car";
    case "garage-queen":
      return "Garage Queen";
    case "part-out":
      return "Part Out";
    case "grocerygetter":
      return "Grocery Getter";
    case "beater":
      return "Beater";
    case "race":
      return "Race Car";
    case "carsandcoffee":
      return "Cars & Coffee";
    case "canyoncarver":
      return "Canyon Carver";
    case "hopefulrestoration":
      return "Hopeful Restoration";
    case "lostcause":
      return "Lost Cause";
    case "historic":
      return "Historical";
    case "shitbox":
      return "Shitbox";
    case "concours":
      return "Concours";
    case "specialoccasion":
      return "Special Occasion";
    case "meetup":
      return "Meetup";
    case "popup":
      return "Popup";
    case "fundraiser":
      return "Fundraiser";
    case "nonprofit":
      return "Non-Profit";
    case "tech":
      return "Tech Session";

    default:
      return "General";
  }
}