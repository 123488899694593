import React, { useState } from "react";
import Pane from "components/modals/Pane";
import LoginForm from "components/globals/user/LoginForm";
import { NavLink , Link } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";
import { useAuth } from "providers/AuthContext";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import { FaChevronRight } from "react-icons/fa";

import one from "assets/images/1.jpg";
import two from "assets/images/2.jpg";
import three from "assets/images/3.jpg";



import cover from "assets/images/home.jpg";
import coverMobile from "assets/images/homeMobile.jpg";

export default function Welcome() {
  const [loginPane, setLoginPane] = useState(false);
  const { userInfo } = useAuth();

  const showLogin = function () {
    setLoginPane(true);
  };

  const hideLogin = function () {
    setLoginPane(false);
  };

  const sections = [
    { 
      params: {
        elementId: "newPosts", 
        headingTitle: "Recent User Posts",
        shortTitle: "Posts",
        headingButtonUrl: null,
        headingButtonText: null,
        apiPath: "post/type/all",
        omit: "none",
        limit: "12",
        type: "post", 
        pagination: false,
      },
      displayOptions: {
        layout: "card",
        yoursBadge: false,
        userBadge: false,
        typeBadge: true,
        categoryBadge: false,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: false,
      }
    },
    { 
      params: {
        elementId: "newCars", 
        headingTitle: "Recent User Cars",
        headingButtonUrl: null,
        headingButtonText: null,
        apiPath: "garage",
        omit: "none",
        limit: "6",
        type: "car", 
        pagination: false,
      },
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: true,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: false,
      }
    },
  ]

  return (
    <>
      <div className="relative">
        <img src={cover} alt="Open Road Society" className="hidden md:block w-full" />
        <img src={coverMobile} alt="Open Road Society" className="block md:hidden w-full" />
        {/* <div className="absolute bottom-[20%] text-center w-full">
          {!userInfo ? (
            <NavLink
              to="https://airtable.com/appPc1aybLuMDIJvr/paglGTx8hA08bBC4z/form"
              target="blank">
              <Button variant="primary">Apply for membership</Button>
            </NavLink>
          ) : (
            <NavLink
              to="/"
              target="blank">
              <Button variant="primary">Go To Home</Button>
            </NavLink>
          )}
        </div> */}
      </div>

      <div className="ors-rich-text w-main">
        <h1>The Open Road Society</h1>
        <p>Digital Garage & Community based in the northwest.</p>
      </div>

      <div className="w-main my-md">
        <div className="flex items-stretch gap-xs flex-wrap md:flex-nowrap">

          <NavLink 
            to="/about" 
            className="
              group
              w-full 
              md:w-1/2 
              bg-brg 
              text-white 
              rounded-largeCard 
              overflow-hidden
              h-auto 
              md:h-[600px]
              flex
              items-end
              relative">

            {/* bg image */}
            <div className="absolute inset-0 z-[1] bg-cover bg-center transition group-hover:scale(1.1)" style={{
              backgroundImage: `url(${one})`
            }}></div>
            
            {/* bg overlay */}
            <div className="absolute inset-0 z-[2] bg-black opacity-[0.3] transition group-hover:opacity-[0.1]"></div>

            {/* content */}
            <div className="p-sm md:p-md mt-[150px] md:mt-0 w-full relative z-[3]">
              <h2 className="mr-[50px] md:mr-[200px] font-bold text-3xl md:text-5xl">About The Open Road Society</h2>
              <FaChevronRight className="absolute bottom-sm md:bottom-md right-sm md:right-md text-2xl group-hover:translate-x-[10px] transition" />
            </div>
          
          </NavLink>

          <div className="flex gap-xs flex-wrap flex-column w-full md:w-1/2">

            <NavLink
              to="/features"
              className="bg-brg text-white rounded-largeCard overflow-hidden w-full flex items-end relative group"
            >

              {/* bg image */}
              <div className="absolute inset-0 z-[1] bg-cover bg-center transition group-hover:scale(1.1)" style={{
                backgroundImage: `url(${two})`
              }}></div>
              
              {/* bg overlay */}
              <div className="absolute inset-0 z-[2] bg-black opacity-[0.3] transition group-hover:opacity-[0.1]"></div>

              <div className="p-sm md:p-md mt-[150px] md:mt-0 w-full relative z-[3]">
                <h2 className="mr-[50px] md:mr-[200px] font-bold text-3xl md:text-4xl">Features</h2>
                <FaChevronRight className="absolute bottom-sm md:bottom-md right-sm md:right-md text-2xl group-hover:translate-x-[10px] transition" />
              </div>
            </NavLink>

            <Link 
              to="https://airtable.com/appPc1aybLuMDIJvr/paglGTx8hA08bBC4z/form"
              className="bg-brg text-white rounded-largeCard overflow-hidden w-full flex items-end relative group"
            >

              {/* bg image */}
              <div className="absolute inset-0 z-[1] bg-cover bg-center transition group-hover:scale(1.1)" style={{
                backgroundImage: `url(${three})`
              }}></div>
              
              {/* bg overlay */}
              <div className="absolute inset-0 z-[2] bg-black opacity-[0.3] transition group-hover:opacity-[0.1]"></div>

              <div className="p-sm md:p-md mt-[150px] md:mt-0 w-full relative z-[3]">
                <h2 className="mr-[50px] md:mr-[200px] font-bold text-3xl md:text-4xl">Apply for Membership</h2>
                <FaChevronRight className="absolute bottom-sm md:bottom-md right-sm md:right-md text-2xl group-hover:translate-x-[10px] transition" />
              </div>
            </Link>

          </div>

        </div>
      </div>
      
      <div className="w-main my-md">

        <div className={userInfo ? "" : "pointer-events-none"}>
          {sections.map(({params, displayOptions}) => (
            <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
          ))}
        </div>

        <div className="my-lg text-center">
          {!userInfo && (
            <div className="flex items-center gap-2">
              <NavLink className="button" onClick={showLogin}>
                <Button variant="primary">Login</Button>
              </NavLink>
              <NavLink className="button" to="https://airtable.com/appPc1aybLuMDIJvr/paglGTx8hA08bBC4z/form" target="blank">
                <Button variant="secondary">Apply for membership</Button>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <Pane visible={loginPane} onClose={hideLogin}>
        <LoginForm onClose={hideLogin} />
      </Pane>
    </>
  );
}
