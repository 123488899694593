import React from "react";
import { useAuth } from "providers/AuthContext";
import { DashboardTemplate } from "./Dashboard";
import {ORSMasterListing} from "components/globals/ORSMasterListing"

export default function Dashboard() {
  const { userInfo, isFetching } = useAuth();

  const sections = [
    {
      params: {
				elementId: "cars", 
				headingTitle: "Your Cars",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/garage",
				omit: "none",
				limit: "6",
				type: "car", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/car"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      },
    },
    {
      params: {
				elementId: "yourProjects", 
				headingTitle: "Your Projects",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/projects",
				omit: "none",
				limit: "6",
				type: "project", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/project"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      },
    },
    // {
    //   params: {
		// 		elementId: "yourGroups", 
		// 		headingTitle: "Your Groups",
		// 		headingButtonUrl: null,
		// 		headingButtonText: null,
		// 		apiPath: "protected/groups",
		// 		omit: "none",
		// 		limit: "6",
		// 		type: "project", 
		// 		pagination: true,
    //     protected: true,
    //     createButton: true,
    //     createButtonPath: "/new/group"
		// 	},
    //   displayOptions: {
    //     layout: "row",
    //     yoursBadge: false,
    //     typeBadge: false,
  //      categoryBadge: false,
    //     userBadge: false,
    //     carBadge: false,
    //     carDetails: false,
    //     dateDetails: false,
    //     rowColumns: true,
    //     mobileCarousel: false,
    //     likes: true,
    //   },
    // },
    {
      params: {
				elementId: "yourEvents", 
				headingTitle: "Your Events",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/events",
				omit: "none",
				limit: "6",
				type: "event", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/event"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      },
    },
    {
      params: {
				elementId: "yourListings", 
				headingTitle: "Your Listings",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/post/type/listings",
				omit: "none",
				limit: "6",
				type: "post", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/post"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      },
    },
    {
      params: {
				elementId: "yourWants", 
				headingTitle: "Your Wants",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "protected/post/type/wants",
				omit: "none",
				limit: "6",
				type: "post", 
				pagination: true,
        protected: true,
        createButton: true,
        createButtonPath: "/new/post"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      },
    },
  ]

  return (
    <>
      {!isFetching && userInfo ? (
        <DashboardTemplate userInfo={userInfo} title="My Content">
          {sections.map(({params, displayOptions}) => (
            <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
          ))}
        </DashboardTemplate>
      ) : null}
    </>
  );
}
