import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "helpers/utils";

const buttonVariants = cva(
  "inline-flex relative items-center font-bold justify-center whitespace-nowrap rounded-md transition focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: 
          "bg-tangerine text-white hover:bg-guards",
        secondary:
          "bg-speed text-brg hover:bg-guards hover:text-white",
        dark:
          "bg-brg text-white hover:bg-speed hover:text-black",
        secondaryActive:
          "bg-guards text-white hover:bg-guards hover:text-white",
        outline:
          "border-[2px] border-brg text-brg hover:bg-brg hover:text-white",
        outlineActive:
          "border-[2px] border-brg bg-brg text-white",
        invertedOutline:
          "border-[2px] border-white text-white hover:bg-white hover:text-brg",
        invertedOutlineActive:
          "border-[2px] border-white bg-white text-brg",
        destructive:
          "border-[2px] border-guards text-guards hover:bg-guards hover:text-white",
        ghost: 
          "bg-secondary text-black hover:bg-black hover:text-white",
        link: 
          "text-brg hover:text-tangerine",
      },
      size: {
        default: "h-7 px-2 md:px-3 text-sm md:h-8 md:px-4",
        sm: "h-4 px-2 text-xs md:h-8 md:px-3",
        lg: "h-8 px-8 md:h-10 md:px-10",
        icon: "h-9 w-9",
      },
      active: {
        true: "bg-guards text-white pointer-events-none",
        false: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      active: false,
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, active = false, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, active, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
