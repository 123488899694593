import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import { useParams } from "react-router-dom";

export default function ArticlesCategory() {
	const { category } = useParams();

	// const sections = [
  //   { 
  //     params: {
	// 			elementId: "cars", 
	// 			headingTitle: "Recent Cars",
	// 			headingButtonUrl: "",
	// 			headingButtonText: "",
	// 			apiPath: "garage",
	// 			omit: "none",
	// 			limit: "6",
	// 			type: "car", 
	// 			pagination: true,
	// 		},
  //     displayOptions: {
  //       layout: "card",
  //       yoursBadge: true,
  //       typeBadge: false,
	// 			 categoryBadge: false,
  //       userBadge: true,
  //       carBadge: false,
  //       carDetails: true,
  //       dateDetails: false,
  //       rowColumns: true,
  //       mobileCarousel: false,
	//			 likes: true,
  //     }
  //   }
  // ]


	return (
		<>
			<div className="relative">
				<div className="bg-black text-white p-lg text-3xl font-bold">Featured Articles Here</div>
			</div>

			<ORSSubnav>
				<SubnavItem to="/articles" label="All" />
				<SubnavItem to="/articles" label="Categories Here" />
			</ORSSubnav>

			<ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/" label="Articles" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label={category} />
			</ORSBreadcrumbs>

			<PSPage>
				{/* {sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))} */}
			</PSPage>
		</>
	);
};
