import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";

export default function People() {

  const sections = [
    { 
      params: {
        elementId: "allMembers", 
        headingTitle: "Members",
        shortTitle: "Members",
        headingButtonUrl: "",
        headingButtonText: "",
        apiPath: "users",
        omit: "none",
        limit: "24",
        type: "users", 
        pagination: true,
      },
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        userBadge: false,
        typeBadge: false,
        categoryBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: false,
        mobileCarousel: false,
        likes: false,
      }
    },
  ]

  return (
    <>

      <ORSSubnav>
        <SubnavItem to="/society" label="Society" />
        <SubnavItem active to="/society/members" label="Members" />
        {/* <SubnavItem to="/society/groups" label="Groups" /> */}
        <SubnavItem to="/society/events" label="Events" />
        <SubnavItem to="/society/rallys" label="Drives & Rallys" />
      </ORSSubnav>

      <ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/society" label="Society" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="Members" />
			</ORSBreadcrumbs>
      
      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
