import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

export default function TapButton({
  Icon,
  badge,
  title,
  description,
  link,
  onClick,
  bgColor = "guards",
  textColor = "white",
  inGrid = false
}) {
  const buttonClasses = clsx(
    `bg-${bgColor}`,
    `text-${textColor}`,
    "p-3",
    "rounded-lg",
    "pt-lg",
    "relative",
    inGrid && "h-full"
  );

  // If link is provided, render NavLink, otherwise render a button with onClick
  if (link) {
    return (
      <NavLink className="button" to={link}>
        <div className={buttonClasses}>
          <span className="flex items-center absolute top-2 left-2">
            {Icon && <Icon className="mr-1 text-lg" />}
            {badge && <span className="text-sm font-bold relative mt-[1px]">{badge}</span>}
          </span>
          <span className="text-3xl font-bold">{title}</span>
          {description && (
            <div className="font-mono text-xs leading-tight italic">{description}</div>
          )}
        </div>
      </NavLink>
    );
  }

  // Render clickable div when no link is provided
  return (
    <div className="button" onClick={onClick}>
      <div className={buttonClasses}>
        <span className="flex items-center absolute top-2 left-2">
          {Icon && <Icon className="mr-1 text-lg" />}
          {badge && <span className="text-sm font-bold relative mt-[1px]">{badge}</span>}
        </span>
        <span className="text-3xl font-bold">{title}</span>
        {description && (
          <div className="font-mono text-xs leading-tight italic">{description}</div>
        )}
      </div>
    </div>
  );
}