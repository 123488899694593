import React from "react";
import PSPage from "components/globals/ui/layout/Page"
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import desktop1 from "assets/images/carousel/desktop/1.jpg";
// import desktop2 from "assets/images/carousel/desktop/2.jpg";
import mobile1 from "assets/images/carousel/mobile/1.jpg";
// import mobile2 from "assets/images/carousel/mobile/2.jpg";
import ORSVersion from "components/globals/ORSVersion";
import {ORSGridLinks, ORSGridLink} from "components/globals/ORSGridLinks";
import MarketingGallery from "components/globals/image/MarketingGallery";

const sections = [
  { 
    params: {
      elementId: "newPosts", 
      headingTitle: "Recent User Posts",
      shortTitle: "Posts",
      headingButtonUrl: "/posts",
      headingButtonText: "View All",
      apiPath: "post/type/all",
      omit: "none",
      limit: "24",
      type: "post", 
      pagination: true,
    },
    displayOptions: {
      layout: "card",
      yoursBadge: true,
      userBadge: true,
      typeBadge: true,
      categoryBadge: true,
      carBadge: true,
      carDetails: true,
      dateDetails: true,
      rowColumns: true,
      mobileCarousel: false,
      likes: true,
    }
  },
  { 
    params: {
      elementId: "newCars", 
      headingTitle: "Recent User Cars",
      shortTitle: "Cars",
      headingButtonUrl: "/cars/all",
      headingButtonText: "View All",
      apiPath: "garage",
      omit: "none",
      limit: "6",
      type: "car", 
      pagination: false,
    },
    displayOptions: {
      layout: "card",
      yoursBadge: true,
      typeBadge: false,
      categoryBadge: true,
      userBadge: true,
      carBadge: true,
      carDetails: true,
      dateDetails: false,
      rowColumns: true,
      mobileCarousel: false,
      likes: true,
    }
  },
  { 
    params: {
      elementId: "newUsers", 
      headingTitle: "Recent Users",
      shortTitle: "Users",
      headingButtonUrl: "/society/members",
      headingButtonText: "View All",
      apiPath: "users",
      omit: "none",
      limit: "6",
      type: "user", 
      pagination: false,
    },
    displayOptions: {
      layout: "row",
      yoursBadge: false,
      typeBadge: false,
      categoryBadge: false,
      userBadge: false,
      carBadge: false,
      carDetails: false,
      dateDetails: false,
      rowColumns: true,
      mobileCarousel: false,
      likes: true,
    }
  },
  // { 
  //   params: {
  //     elementId: "newGroups", 
  //     headingTitle: "Recent Groups",
  //     shortTitle: "Groups",
  //     headingButtonUrl: "/groups/all",
  //     headingButtonText: "View All",
  //     apiPath: "group",
  //     omit: "none",
  //     limit: "6",
  //     type: "group", 
  //     pagination: false,
  //   },
  //   displayOptions: {
  //     layout: "card",
  //     yoursBadge: true,
  //     typeBadge: false,
  //     categoryBadge: false,
  //     userBadge: true,
  //     carBadge: true,
  //     carDetails: true,
  //     dateDetails: false,
  //     rowColumns: true,
  //     mobileCarousel: false,
  //     likes: true,
  //   }
  // },
];

const carousel = [
  {
    image: desktop1,
    mobileImage: mobile1,
    title: "Welcome!",
    description: "Site launch April 2025",
    btnText: "Read More",
    url: "/article/38bd8c72c2d24cc587d3b4c37c270c8e"
  }
]

export default function Home() {

  return (

    <>
      <MarketingGallery slides={carousel} />
      <PSPage>

        <div className="my-sm p-xs sm:p-sm bg-[#ffffff] rounded-lg">
          <div className="ors-rich-text mb-sm full">
            <h3 className="!mt-0">Private Beta</h3>
            <p>
              Since the site is currently in active development, 
              there will be a lot of changes and updates happening 
              frequently. If you notice anything out of place,  
              please <a href="mailto:mattaebersold@gmail.com">reach out to us</a>.
            </p>

            <p>
              Take a look at these pages frequently, as they will have the 
              most up-to-date changes and notes on what's coming up.
            </p>
          </div>

          <div className="mb-sm">
            <ORSVersion />
          </div>

          <ORSGridLinks>
            <ORSGridLink link="/roadmap" bg="tangerine" text="Roadmap" />
            <ORSGridLink link="/changelog" bg="speed" text="Changelog" />
          </ORSGridLinks>
        </div>

        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
}