import { React, memo } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
import User from "components/globals/user/badge";
import CarBadge from "components/globals/car/badge";
import PSRowPrice from "components/globals/ui/row/Price"
import PSRowTitle from "components/globals/ui/row/Title"
import PSRowDate from "components/globals/ui/row/Date"
import ORSYearMakeModel from "components/globals/car/ORSYearMakeModel";
import {TypeBadge} from "components/globals/ui/TypeBadge";
import IsYoursBadge from 'components/globals/IsYoursBadge';
import { useSelector, shallowEqual } from 'react-redux';
import EntryUserActions from "components/globals/ui/card/EntryUserActions"
import { getEntryTypePath } from "types/baseTypes";
import { usePostPane } from '../../../providers/PostPaneContext';
import FollowBtn from 'components/globals/ui/FollowBtn';
import { FaCar, FaRegEnvelope } from "react-icons/fa";
import { formatDate } from "helpers/utils";

function Row({
	item,
	price,
	carInfo,
	displayOptions,
}) {

	const isLoggedIn = useSelector(state => state.auth.isLoggedIn, shallowEqual);

	const { openPostPane } = usePostPane();
	
	const triggerPostPane = () => {
    openPostPane({ item: item });
  };

	return (
		<>
			{item.entry_type === 'user' ? (
				<div className="
					flex
					relative
					mx-0
					mb-2
					border-[1px]
					border-secondary
					rounded-full
					group
					hover:bg-secondary
					transition
					overflow-hidden
				"
					key={item.internal_id}
				>


					<Link 
						className="flex w-full overflow-hidden items-center h-full grow p-[4px]"
						to={`/users/${item.username}`}>
						
						<div className="relative rounded-full w-[40px] h-[40px] md:w-[80px] md:h-[80px] border-1 border-black overflow-hidden mr-3 md:mr-xs">
							<div className="absolute inset-0 transition group-hover:scale-[1.1] ">
								<Single image={item.gallery[0]} user />
							</div>
						</div>

						<div>
							<h4 className="font-bold text-sm md:text-lg">{item.username}</h4>
							<div>
								<span
									className="
										font-bold hidden md:inline-block text-[11px] p-1 px-3 rounded-lg bg-secondary transition group-hover:bg-secondary-dark
									"
								>
									Member Since: {formatDate(item?.created_at)}
								</span>
							</div>
						</div>
					</Link>

					<div className="group-hover:border-secondary-dark pl-xs pr-xs flex items-center shrink-0">
						{item.allowEmail && (
							<Link to={`mailto:${item.email}`} className="block text-brg p-2 md:p-4 rounded-full hover:bg-[#cccccc] z-[1]">
								<FaRegEnvelope />
							</Link>
						)}
					</div>
				</div>
			) : (
			<div className="
				flex
				relative
				mx-0
				my-3
				md:my-0
				shadow-[0_0_15px_10px_rgba(0,0,0,0.06)]" 
				key={item.internal_id}
			>
				
				<div className='relative w-full'>
					
					<Link 
						className="flex w-full overflow-hidden items-stretch h-full"
						to={`/${getEntryTypePath(item.entry_type)}/${item.internal_id}`}
						onClick={(e) => {
							if (item.entry_type === "post") {
								e.preventDefault();
								triggerPostPane();
							}
						}}
					>
						<div className="relative overflow-hidden w-[100px] md:w-[150px] aspect-square md:aspect-[4/3] shrink-0">
							<Single image={item.gallery[0]} />
							{displayOptions.typeBadge && (
								<div className="hidden md:block absolute top-2 left-2">
									<TypeBadge entryType={item.entry_type} type={item.type} category={item.category} />
								</div>
							)}
						</div>

						{price && (
							<PSRowPrice price={item.price} />
						)}

						<div className="grow p-3 pr-10">

							<PSRowTitle title={item.title} />
							{displayOptions.typeBadge && (
								<div className="block md:hidden inline-block">
									<TypeBadge entryType={item.entry_type} type={item.type} category={item.category} />
								</div>
							)}
														
							{displayOptions.yoursBadge && (
								<div className="my-2">
									<IsYoursBadge comparison={item.user_id} />
								</div>
							)}

							{displayOptions.dateDetails && (
								<PSRowDate date={item.created_at} />
							)}

							{displayOptions.userBadge && (
								<span className="mr-2 mb-2">
									<User userId={item.user_id} noLink />
								</span>
							)}

							{item.car_id && (
								<span className="mr-2 mb-2">
									<CarBadge car_id={item.car_id} noLink />
								</span>
							)}

							{carInfo && (
								<ORSYearMakeModel entry={{
									year: item.year,
									make: item.make,
									model: item.model,
									trim: item.trim
								}} />
							)}
						</div>
					</Link>

					{ isLoggedIn && (
						<div className="absolute top-0 right-2">
							<EntryUserActions comparison={item.user_id} item={item} />
						</div>

					)}

				</div>
			</div>
			)}
			
		</>
  );
}

export default memo(Row);
